'use client';
import {
  DarkModeButton,
  DataToast,
  Dropdown,
  Menu,
  Person,
  PopMessages,
  SideMenu
} from 'components';
import Footer from 'components/Footer';
import { TOOLTIP_PLACES } from 'constants/constants';
import { IMPERSONATE_ID_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import { LogoClipArt } from 'lib/ClipArt';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import { useFeatureFlags } from 'providers/ClientProviders/useFeatureFlags';
import { useCurrentUser } from 'providers/UserProvider/useCurrentUser';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import userXAnimatedIcon from 'react-useanimations/lib/userX';
import { cn } from 'utils/cn';
import { setPersistedLocale } from 'utils/setPersistedLocale';
import { useMenuContext } from '../_context/MenuContext';
const MainAuth: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const t = useTranslations();
  const pathname = usePathname();
  const { refMenu } = useMenuContext();
  const {
    data: currentUser,
    refetch,
    isFetchedAfterMount: isFetchedAfterMountCurrentUser
  } = useCurrentUser();
  const router = useRouter();
  const [impersonateToken, setImpersonateToken] = usePersistentContext(
    IMPERSONATE_ID_PERSISTED_STATE
  );
  const { data: featureFlags } = useFeatureFlags();

  const isNotChatAi = !pathname.includes('/smart-tasks/chat-ai');

  const handleUnBecomeMe = useCallback(async () => {
    setImpersonateToken('');
    await refetch();
    toast.success(DataToast, {
      data: { content: t('messages.success.become') },
      autoClose: 1000,
      position: 'top-right'
    });
    return router.push('/prefetch');
  }, [refetch, router, setImpersonateToken, t]);

  const items = useMemo(
    () => [
      { name: t('components.user_dropdown.sign_out'), href: '/auth/sign-out' },
      ...(!!impersonateToken
        ? [
            {
              name: t('components.user_dropdown.become_me'),
              icon: userXAnimatedIcon,
              onClick: handleUnBecomeMe
            }
          ]
        : [])
    ],
    [handleUnBecomeMe, impersonateToken, t]
  );

  useEffect(() => {
    if (isFetchedAfterMountCurrentUser && currentUser?.locale && !impersonateToken)
      setPersistedLocale(currentUser?.locale);
  }, [currentUser, isFetchedAfterMountCurrentUser, impersonateToken]);

  return (
    <>
      <Tooltip id="tolq-info" className="react-tooltip-tolq-info" />
      {TOOLTIP_PLACES.map((place) => (
        <Tooltip
          key={place}
          id={`tolq-info-${place}`}
          className="react-tooltip-tolq-info"
          place={place}
          offset={0}
        />
      ))}
      <Tooltip id="tolq-tooltip" className="react-tooltip-tolq" />
      {TOOLTIP_PLACES.map((place) => (
        <Tooltip
          key={place}
          id={`tolq-tooltip-${place}`}
          className="react-tooltip-tolq"
          place={place}
        />
      ))}
      <div className="flex w-full flex-col">
        <nav className="fixed z-[98] flex h-20 w-full items-center bg-primary-blue-100 text-gray-600">
          <div className="flex w-full flex-row justify-between px-6 py-5 md:flex-row">
            <a className="mb-4 flex items-center font-medium text-white md:mb-0">
              <LogoClipArt />
            </a>
            <div className="flex items-center">
              {featureFlags?.darkModeEnabled && <DarkModeButton />}
              <Dropdown items={items}>
                <Person />
              </Dropdown>
            </div>
          </div>
        </nav>
        <nav className="h-20 bg-tertiary-900" />
        <div className="flex w-full flex-col" ref={refMenu}>
          <div className="flex w-full flex-col">
            <SideMenu />
            <div className="flex w-full flex-1">
              <div className="fixed -top-96 z-50 h-[1000vh] shadow-[0px_2px_8px_0px_rgba(36,92,144,0.17),0_8px_8px_-8px_rgba(36,92,144,0.17)_inset]">
                <div className="flex w-60 flex-1 flex-col bg-white dark:bg-quaternary-200" />
              </div>
              <div className="z-50">
                <div className="flex w-60" />
                <Menu />
              </div>
              <div className="flex w-full flex-col ">
                <div className={cn('w-full', 'bg-tertiary-900 dark:bg-quaternary-100')}>
                  <div className="flex w-full justify-center">
                    <div
                      className={cn('w-full', isNotChatAi ? 'max-w-7xl' : '')}
                      style={{ minHeight: 'calc(100vh - 80px)' }}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isNotChatAi && <Footer />}
        </div>
        {!impersonateToken && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && (
          <PopMessages />
        )}
      </div>
    </>
  );
};
export default MainAuth;
