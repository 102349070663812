'use client';

import {
  FACEBOOK_LINK,
  LINKEDIN_LINK,
  PRIVACY_POLICY_LINK,
  SUPPORT_LINK,
  TERMS_CONDITIONS_LINK,
  TWITTER_LINK
} from 'constants/links';
import { SocialFacebookIcon, SocialLinkedinIcon, SocialTwitterIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { FC, PropsWithChildren } from 'react';
import Typography from './Typography';

const Footer: FC<PropsWithChildren<unknown>> = ({}) => {
  const t = useTranslations();
  return (
    <footer className="ml-[240px] inline-flex h-28 w-[calc(100%_-_240px)] flex-col items-center justify-start gap-3 bg-tertiary-900 px-6 py-12">
      <div className="relative flex h-6 flex-col items-start justify-start self-stretch">
        <div className="absolute -bottom-1 left-0 ml-4 flex h-6 items-center justify-start gap-0">
          <Typography variant="light4" className="mr-4 text-tertiary-200">
            © {new Date().getFullYear()} Tolq.com BV
          </Typography>
          <div className="ml-4 flex h-6 items-center justify-start gap-6">
            <a href={TWITTER_LINK} target="_blank">
              <SocialTwitterIcon className="transition-all hover:!text-primary-blue-100" />
            </a>
            <a href={LINKEDIN_LINK} target="_blank">
              <SocialLinkedinIcon className="transition-all hover:!text-primary-blue-100" />
            </a>
            <a href={FACEBOOK_LINK} target="_blank">
              <SocialFacebookIcon className="transition-all hover:!text-primary-blue-100" />
            </a>
          </div>
        </div>
        <div className="inline-flex items-center justify-center self-stretch">
          <div className="ml-10 flex h-6 items-start justify-center gap-6">
            <a href={TERMS_CONDITIONS_LINK} target="_blank">
              <Typography
                variant="regular2"
                className="text-tertiary-300 hover:text-secondary-link"
              >
                {t('footer.terms')}
              </Typography>
            </a>
            <a href={PRIVACY_POLICY_LINK} target="_blank">
              <Typography
                variant="regular2"
                className="text-tertiary-300 hover:text-secondary-link"
              >
                {t('footer.privacy')}
              </Typography>
            </a>
            <a href={SUPPORT_LINK} target="_blank">
              <Typography
                variant="regular2"
                className="text-tertiary-300 hover:text-secondary-link"
              >
                {t('footer.support')}
              </Typography>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
